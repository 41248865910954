@import '../node_modules/bootstrap/scss/functions';

$primary: #12adc9;
$secondary: #f7f7e2;
$light-gray: #eff1f4;
$red: #f94e3f;
$blue: #0075c2;
$font-weight-base: bolder;
html {
  --bs-font-sans-serif: 'M PLUS 1p';
}

// Required
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables-dark';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/root';

// Optional
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/helpers/position';
@import '../node_modules/bootstrap/scss/utilities/api';

// general elements
body {
  background: #fff;
}

.btn-primary {
  color: white;
}
section {
  // #anchor へのリンクのため padding 多め
  padding: 5rem 1.5rem;
  header {
    margin-bottom: 2.5rem;
    h2 {
      text-align: center;
      font-weight: bolder;
      width: max-content;
      margin: auto;
      border: solid $primary;
      border-width: 0 1.85rem 1rem 0;
      border-image: url('img/header_line.png') 0 34 100 0 round;
    }
  }
  & > p {
    font-weight: normal;
    letter-spacing: 0.01rem;
  }
}
section.section {
  margin: 0 auto;
  max-width: 800px;
}

// individual elements
.navbar {
  .navbar-brand img {
    height: 1.8rem;
  }
  li.nav-item {
    margin-left: 0.5rem;
  }
}
.navbar-light .navbar-nav {
  font-size: 0.94rem;
  font-weight: normal;
  margin-top: 0.4rem;
}
@media screen and (min-width: 992px) {
  .navbar-light .navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
}
.navbar-light .navbar-nav .nav-link.install {
  background: $primary;
  border-radius: 20px;
  color: #fff;
  margin-top: 0.2rem;
  padding: 0.4rem 1rem;
  &:hover {
    opacity: 0.8;
  }
}
@media screen and (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link.install {
    margin-top: 0;
    margin-left: 0.5rem;
  }
}
.navbar-light .navbar-nav .nav-link.form {
  padding: 0.7rem 0 0.5rem;
  &:hover {
    opacity: 0.8;
  }
  img {
    margin-right: 0.2rem;
    height: 1.2rem;
  }
}
@media screen and (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link.form {
    margin: 0 0 0 0.3rem;
    padding: 0 0.5rem;
    img {
      margin-right: 0;
      height: 1.7rem;
    }
    span {
      display: none;
    }
  }
}

$fukidashi-stroke: 20px;
$fukidashi-color: #0749a2;
$width-break: 970px;
#top {
  background-color: $primary;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 0 0.8rem;
  position: relative;
  @media (max-width: calc(#{$width-break} - 1px)) {
    flex-direction: column-reverse;
    padding: 0;
  }
  &::before {
    content: " ";
    display: block;
    background: url('img/logo.svg') 0 0 no-repeat;
    background-size: contain;
    position: absolute;
    left: -40px;
    top: 130px;
    margin: auto;
    mix-blend-mode: overlay;
    height: 200px;
    width: 900px;
    z-index: 0;
    @media (max-width: calc(#{$width-break} - 1px)) {
      top: auto;
      left: -30px;
      bottom: 50px;
      height: 100px;
      width: 700px;
    }
  }
  &::after {
    content: " ";
    display: block;
    background: #fff;
    position: absolute;
    bottom: -420px;
    right: -50%;
    height: 400px;
    width: 100%;
    transform-origin: 0 0;
    transform: rotate(-25deg);
    z-index: 0;
  }
  .block1 {
    flex-grow: 0;
    align-self: flex-end;
    min-width: 328px;
    text-align: center;
    z-index: 1;
    @media (max-width: calc(#{$width-break} - 1px)) {
      align-self: center;
      min-width: 160px;
    }
    .bot {
      .cropper {
        overflow: hidden;
        position: relative;
        padding-top: 115%;
        img {
          width: 100%;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  @media (max-width: calc(#{$width-break} - 1px)) {
    flex-wrap: wrap;
    .block1 {
      position: relative;
      .bot {
        width: 100%;
      }
    }
  }
  .block2 {
    align-self: center;
    margin: 0 1rem 0 0;
    padding: 2.5rem 0 1rem;
    @media (max-width: calc(#{$width-break} - 1px)) {
      display: flex;
      flex-direction: column-reverse;
      margin: 0;
      padding: 4.5rem 0 0;
    }
    h1 {
      background: url('img/fukidashi.svg') 0 0 no-repeat;
      background-size: 320px auto;
      color: #000;
      font-weight: bold;
      font-size: 1.5rem;
      letter-spacing: 0.02rem;
      line-height: 1.5;
      margin: 3rem auto 0;
      padding: 3.6rem 0 5.5rem;
      position: relative;
      text-align: center;
      width: 320px;
      @media (max-width: calc(#{$width-break} - 1px)) {
        background-size: 160px auto;
        font-size: 0.8rem;
        letter-spacing: 0.01rem;
        line-height: 1.4;
        margin: 0.5rem auto;
        padding: 2rem 0 2.2rem;
        width: 160px;
      }
    }
    p {
      color: #fff;
      margin: 0;
      font-size: 1.2rem;
      font-weight: normal;
      letter-spacing: 0.025rem;
      max-width: 35rem;
      @media (max-width: calc(#{$width-break} - 1px)) {
        font-size: 0.96rem;
        padding: 0 0.8rem 0.5rem;
        text-align: center;
        word-break: break-word;
      }
    }
  }
}

#about {
  background: $light-gray;
  text-align: center;
  padding: 4rem 0;
  @media (max-width: calc(#{$width-break} - 1px)) {
    padding: 2rem 1rem;
  }
  .block1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    h3 {
      font-weight: bolder;
      line-height: 1.5;
      text-align: center;
      margin: 0 0 0 auto;
      padding: 0;
      border: none;
      border-width: 0;
      border-image: none;
      @media (max-width: calc(#{$width-break} - 1px)) {
        max-width: 590px;
      }
    }
    img {
      max-width: 400px;
      width: 100%;
    }
  }
  .block2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h3 {
      font-weight: bolder;
      line-height: 1.5;
      text-align: center;
      margin: 0 0 0 auto;
      padding: 0;
      border: none;
      border-width: 0;
      border-image: none;
      @media (max-width: calc(#{$width-break} - 1px)) {
        max-width: 590px;
      }
    }
    img {
      max-width: 400px;
      width: 100%;
    }
  }
}

#intro {
  background: $primary;
  text-align: center;
  padding: 0;
  header {
    background: #fff;
    margin: 0;
    padding: 5rem 0 2.5rem;
  }
  .modules {
    background: #fff;
    clip-path: ellipse(90% 100% at center top);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 3rem;
    @media (max-width: calc(#{$width-break} - 1px)) {
      clip-path: ellipse(150% 100% at center top);
      padding: 0 0 2rem;
    }
  }
  .mod {
    border-radius: 50%;
    background: #f7f7e2;
    max-width: 370px;
    margin: 2rem;
    .title1 {
      color: $primary;
      font-weight: normal;
      letter-spacing: 0.02rem;
      &::before,
      &::after {
        content: '';
        height: 2px;
        width: 2.5em;
        margin: 0.3em 0.7em;
        background: $primary;
        display: inline-block;
      }
    }
    .title2 {
      a {
        color: inherit;
        text-decoration: none;
      }
      font-family: 'Overpass', sans-serif;
      font-size: 3.5rem;
      line-height: 4rem;
      margin: 0 0 0.1em;
      &::first-letter {
        color: $primary;
      }
    }
    .fukidashi {
      font-weight: normal;
      letter-spacing: 0.01rem;
      height: 5rem;
      margin: auto;
      padding-top: 1.2rem;
      color: white;
      background: no-repeat center url('img/fukidashi2.svg');
    }
    img {
      width: 30%;
    }
    .desc {
      font-weight: normal;
      margin: 0.5rem 0 0;
      overflow-wrap: break-word;
      word-break: keep-all;
      max-width: 20rem;
    }
  }
  footer {
    background: $primary;
    padding: 2rem 0;
    p {
      color: #fff;
      font-size: 1.3rem;
      font-weight: normal;
      letter-spacing: 0.02rem;
      margin: 1rem;
      overflow-wrap: break-word;
      word-break: keep-all;
    }
  }
}

#price {
  background: $secondary;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  header, p, .red {
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
  }
  .red {
    color: $red;
    font-size: 2.8rem;
  }
  header h2 {
    border-image: url('img/header_line.png') 0 34 100 0 repeat;
  }
}

#faq {
  .block {
    $indent: 1.5rem;
    background: rgba(255, 255, 255, 0.8);
    margin-bottom: 0.5rem;
    padding-left: $indent;
    .q, .a {
      font-weight: normal;
      letter-spacing: 0.01rem;
    }
    .q::before {
      content: 'Q. ';
      color: $red;
      display: inline-block;
      font-weight: bolder;
      width: $indent;
      margin-left: -$indent;
    }
    .a::before {
      content: 'A. ';
      color: $blue;
      display: inline-block;
      font-weight: bolder;
      width: $indent;
      margin-left: -$indent;
    }
  }
  footer {
    text-align: center;
  }
}

#install {
  background-color: $primary;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  .box {
    margin: 2rem auto;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    padding: 2rem;
    max-width: 800px;
    img.logo {
      width: 80%;
    }
    p {
      font-size: 2rem;
      margin-top: 0.5rem;
    }
    a, label {
      font-weight: normal;
      letter-spacing: 0.01rem;
    }
    .form-check-input {
      margin-right: 0.25rem;
    }
  }
}

#form {
  .box {
    padding: 2rem 1rem;
    background: #f7f7e2;
    .form-label span {
      color: red;
    }
    textarea {
      height: 8rem;
    }
    label {
      font-weight: normal;
    }
  }
}

footer.page-footer {
  margin-top: 4rem;
  text-align: center;
  img {
    width: 15rem;
  }
  p {
    color: #aaa;
    font-size: 0.7rem;
  }
}

.pagetop {
  cursor: pointer;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  height: 3rem;
  width: 3rem;
}

#peer_bonus {
  text-align: center;
  header h2 {
    border-image: url('img/header_line.png') 0 34 100 0 repeat;
  }
  p {
    overflow-wrap: break-word;
    word-break: keep-all;
    @media (max-width: 340px) {
      font-size: 0.9rem;
    }
  }
  .modules {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mod {
    margin: 0 auto 2rem;
    .fukidashi {
      font-weight: normal;
      letter-spacing: 0.01rem;
      height: 8rem;
      width: 20rem;
      margin: auto;
      padding-top: 2.5rem;
      color: white;
      background: no-repeat center url('img/fukidashi2.svg');
      background-size: 100%;
      @media (max-width: 340px) {
        font-size: 0.8rem;
        height: 7.5rem;
        width: 16rem;
      }
    }
    img {
      width: 30%;
    }
  }
}
